<template>
  <div class="big">
    <div class="red"></div>
    <div class="mobileNav">
      <img
        @click="goHome()"
        class="logo-nav mx-auto"
        src="../assets/logo_sprogstimulering.png"
        alt="sprogstimulering.dk"
      />
      <ul class="block">
        <li><a href="/foraeldre">Din rolle som forældre</a></li>
        <li>
          <a href="/volunteers">Materialer til systematisk sprogstimulering</a>
        </li>
        <li>
          <a href="/professionelle"
          >Materiale til pædagoger, audiologopæder og lærere</a
          >
        </li>
        <li><a href="/feedback">Feedback</a></li>
      </ul>
    </div>
    <div class="nav">
      <img
        @click="goHome()"
        class="logo-nav m-auto"
        src="../assets/logo_sprogstimulering.png"
        alt="sprogstimulering.dk"
      />
      <ul class="flex m-auto">
        <li><a href="/foraeldre">Din rolle som forældre</a></li>
        <li><h10> | </h10></li>
        <li>
          <a href="/volunteers">Materialer til systematisk sprogstimulering</a>
        </li>
        <li><h10> | </h10></li>
        <li>
          <a href="/professionelle"
            >Materiale til pædagoger, audiologopæder og lærere</a
          >
        </li>
        <li><h10> | </h10></li>
        <li><a href="/feedback">Feedback</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "NavBar",
  methods: {
    goHome() {
      router.push("/");
      scroll(0, 0);
    },
  },
};
</script>

<style scoped>
img {
  -webkit-filter: drop-shadow(2px 2px 2px #222222);
  filter: drop-shadow(2px 2px 2px #222222);
  height: 30px;
}
.big {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.red {
  background-color: #af4343;
  height: 25px;
}
.nav {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: #f3f3f3;
  height: 80px;
  box-shadow: 0 0 25px 0 black;
}
.inlinex * {
  display: inline-block;
}
.nav a {
  font-size: 20px;
  margin: 0 10px;
  white-space: nowrap;
  height: 40px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.logo-nav {
  height: 70px;
  cursor: pointer;
}
a:hover {
  color: #af4343;
}
@media (max-width: 1500px) {
  .nav a {
    font-size: 15px;
  }
}
@media (max-width: 1200px) {
  .nav:hover {
    -webkit-transition: height 0.5s ease-in-out;
    -moz-transition: height 0.5s ease-in-out;
    -ms-transition: height 0.5s ease-in-out;
    -o-transition: height 0.5s ease-in-out;
    transition: height 0.5s ease-in-out;
    height: 100vh;
  }
  .nav:hover {
    margin: 30px auto;
    display: block;
  }
  .nav:hover {
    margin: 30px auto;
    display: block;
  }
  .nav:hover {
    margin: 30px auto;
    display: block;
  }
  .nav:hover .logo-nav {
    display: none;
  }
  .nav:hover li {
    margin: 30px auto;
  }
  .nav:hover h10 {
    display: none;
  }
}
@media (max-width: 1100px) {
  .nav {
    display: none;
  }
}
@media (min-width: 1100px){
  .mobileNav {
    display: none;
  }
}
.mobileNav {
  font-size: 15px;
  height: 80px;
  background-color: #f3f3f3;
}
.mobileNav:hover {
  -webkit-transition: height 0.5s ease-in-out;
  -moz-transition: height 0.5s ease-in-out;
  -ms-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
  height: 100vh;
}
.mobileNav:hover a {
  display: block;
  font-size: 20px;
  margin: 0 10px;
  white-space: nowrap;
  height: 40px;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}
.mobileNav a {
display: none;
}
.mobileNav li {
  margin: 10vh;
}
</style>
