<template>
  <NavBar v-if="['activityStart', 'test', 'activityAdmin', 'login'].indexOf($route.name) <= -1"></NavBar>
  <router-view />
  <ActivityWheelBtn v-if="['activityStart', 'test', 'activityAdmin', 'login'].indexOf($route.name) <= -1"></ActivityWheelBtn>
  <FooterBot v-if="['activityStart', 'test', 'activityAdmin', 'login'].indexOf($route.name) <= -1"></FooterBot>
</template>

<style lang="scss">
@font-face {
  font-family: "EuclidCircularBRegular";
  src: url("../src/assets/EuclidCircularBRegular.ttf");
}

#app {
  font-family: EuclidCircularBRegular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 20px;
  background: rgba(255, 204, 0, 0.6) 0;
}
* {
  margin: 0;
}
.container2 {
  padding: 5vh;
  margin: 50px auto;
  min-height: 30vh;
  position: relative;
  max-width: 90%;
  text-align: left;
  line-height: 200%;
  color: #2c3e50;
}
body {
  min-width: 360px;
}
.title2 {
  font-size: 25px;
  max-width: 90%;
  margin: 20px auto auto;
  line-height: 150%;
}
hr {
  width: 80%;
  margin: 15px auto auto auto;
  border-bottom: 2px solid #ffffff;
}
.boxShadow {
  -webkit-box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 8px 8px 24px 0 rgba(0, 0, 0, 0.5);
}
p {
  font-size: 26px;
  margin: 10px;
  text-decoration: underline;
}
p:hover {
  cursor: pointer;
  color: #efefef;
  text-shadow: 0 0 1px #2c3e50, 0 0 4px #2c3e50;
}
a:visited {
  color: #efefef;
}
.displayScroll {
  @apply scrollbar scrollbar-thumb-amber-400 scrollbar-track-amber-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin;
  height: 90vh;
  display: block;
}
.back {
  height: 3vh;
  width: 100%;
}
</style>
<script>
import NavBar from "@/components/NavBar";
import FooterBot from "@/components/FooterBot";
import ActivityWheelBtn from "@/components/ActivityWheelBtn.vue";
export default {
  components: { ActivityWheelBtn, FooterBot, NavBar },
};

</script>
