<template>
  <div class="box">
    <div class="logos m-auto">
      <h1 class="my-10 text-4xl font-bold">Samarbejdspartnere:</h1>
      <div class="flex flex-wrap p-10 m-auto">
        <img
          class="logo"
          src="../assets/logo1.png"
          alt="center for sprog og laerning"
        />
        <img class="logo" src="../assets/logo2.png" alt="filosofi i skolen" />
        <img
          class="logo"
          style="height: 50px"
          src="../assets/logo4.png"
          alt="sdu"
        />
        <img class="logo" src="../assets/logo3.png" alt="2 timer om ugen" />
      </div>
    </div>
    <div class="contact">
      <h1>Kontakt os:</h1>
      <h2>Campusvej 55, 5230 Odense C</h2>
      <h2>+45 6550 3083</h2>
      <div class="socialMedia">
        <a href="https://www.facebook.com/2timeromugen" target="_blank">
          <img alt="fb" src="../assets/social_logos/fb_logo.png" />
        </a>
        <a href="https://www.instagram.com/2timeromugen/" target="_blank">
          <img alt="ig" src="../assets/social_logos/instagram_logo.png" />
        </a>
        <a
          href="https://www.linkedin.com/company/2timeromugen/"
          target="_blank"
        >
          <img alt="li" src="../assets/social_logos/linkedin_logo.png" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCmSgue7gWkD32boxu090IaA?view_as=subscriber"
          target="_blank"
        >
          <img alt="yt" src="../assets/social_logos/yt_logo.png" />
        </a>
      </div>
      <div class="block mt-10"><a href="https://icons8.com"><h1 class="text-sm text-gray-600">icons8.com</h1></a></div>
    </div>
  </div>
  <div class="last" />
</template>

<script>
export default {
  name: "FooterBot",
};
</script>

<style scoped>
.socialMedia {
  margin-left: auto;
  margin-right: 30px;
  display: flex;
}
.socialMedia img {
  height: 30px;
  margin: 15px;
}
.box {
  -webkit-box-shadow: 0 -29px 97px 18px rgba(0, 0, 0, 0.49) inset;
  display: flex;
  background-color: #ffffff;
  min-width: 100%;
  font-size: 15px;
  min-height: 300px;
  color: #000000;
}
@media (max-width: 700px) {
  .box {
    display: block;
  }
  .logos {
    max-width: 100%;
    border-bottom: rgba(0, 0, 0, 0.91) dotted 5px;
    margin: auto;
  }
  .contact {
    max-width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 700px) {
  .logos {
    display: inline-block;
    width: 70%;
    border-right: rgba(0, 0, 0, 0.91) dotted 5px;
  }
}
.contact {
  max-width: 30%;
  margin: 60px auto;
  justify-content: center;
  align-items: center;
}
.contact h2 {
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
}

.logo {
  margin: 20px;
  height: 80px;
  width: auto;
}
.last {
  height: 50px;
  width: 100%;
  background-color: #af4343;
  box-shadow: inset 0 -5px 23px 8px rgba(0, 0, 0, 0.7);
}
</style>
