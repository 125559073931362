<template>
  <div class="rounded-full w-16 h-16 fixed bottom-8 right-8 drop-shadow-2xl bg-amber-300 flex items-center justify-center">
    <a href="/activityWheel">
      <img class="w-12 h-12" alt="ActivityWheel" src="https://img.icons8.com/external-vitaliy-gorbachev-lineal-vitaly-gorbachev/60/null/external-color-wheel-graphic-design-vitaliy-gorbachev-lineal-vitaly-gorbachev.png"/>
    </a>
  </div>
</template>

<script>
export default {
  name: "ActivityWheelBtn"
};
</script>

<style scoped>
p {
  opacity: 0;
}
img:hover p{
  opacity: 1;
}
</style>