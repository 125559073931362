import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/volunteers",
    name: "volunteers",

    component: () => import("../views/VolunteersView.vue"),
  },
  {
    path: "/feedback",
    name: "feedback",

    component: () => import("../views/FeedbackView.vue"),
  },
  {
    path: "/trolle",
    name: "trolle",

    component: () => import("../views/TrolleView.vue"),
  },
  {
    path: "/kage",
    name: "kage",

    component: () => import("../views/KageView.vue"),
  },
  {
    path: "/bog",
    name: "bog",

    component: () => import("../views/BogView.vue"),
  },
  {
    path: "/foraeldre",
    name: "foraeldre",

    component: () => import("../views/ForaeldreView.vue"),
  },
  {
    path: "/professionelle",
    name: "professionelle",

    component: () => import("../views/ProfessionelleView.vue"),
  },
  {
    path: "/foraeldre/fleresprog",
    name: "fleresprog",

    component: () => import("../views/FlereSprogView.vue"),
  },
  {
    path: "/foraeldre/folelser",
    name: "folelser",

    component: () => import("../views/FolelserView.vue"),
  },
  {
    path: "/foraeldre/fortael",
    name: "fortael",

    component: () => import("../views/FortaelBogView.vue"),
  },
  {
    path: "/foraeldre/godebilledboger",
    name: "godebilledboger",

    component: () => import("../views/GodeBilledbogerView.vue"),
  },
  {
    path: "/audiologopeder",
    name: "audiologopeder",

    component: () => import("../views/AudiologopederView.vue"),
  },
  {
    path: "/test",
    name: "test",

    component: () => import("../views/TestView.vue"),
  },
  {
    path: "/activityWheel/login",
    name: "login",

    component: () => import("../views/ActivityAdminLogin.vue"),
  },
  {
    path: "/activityWheel",
    name: "activityStart",

    component: () => import("../views/ActivityStartView.vue"),
  },
  {
    path: "/activityWheel/admin",
    name: "activityAdmin",
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem('token')
      if (!token) {
        next('/activityWheel/login')
      } else {
        next()
      }
    },

    component: () => import("../views/ActivityAdminView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
